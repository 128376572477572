export const backButtonWrapper = {
  textAlign: 'center',
}

export const backButton = {
  fontSize: '0.8rem',
  cursor: 'pointer',
  outline: 'none',
}

export const backButtonText = {
  color: 'white',
}
